import * as React from 'react';

export interface ArrowProps extends React.HTMLAttributes<HTMLOrSVGElement> {
  style?: React.CSSProperties;
}

class LeftArrowIcon extends React.Component<ArrowProps> {
  public render() {
    const { style, ...props } = this.props;
    return (
      <svg
        width={15}
        height={24}
        viewBox='0 0 15 24'
        style={{ ...arrowStyle, ...style }}
        {...props}
      >
        <g stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
          <g
            id='Widget-Preview-Carousel'
            transform='translate(-408.000000, -500.000000)'
            fill='currentColor'
            fillRule='nonzero'
          >
            <polygon points='423 521.17 413.724882 512 423 502.83 420.137559 500 408 512 420.137559 524'></polygon>
          </g>
        </g>
      </svg>
    );
  }
}

const arrowStyle: React.CSSProperties = {
  cursor: 'pointer',
};

export { LeftArrowIcon };
