import * as React from 'react'
import { IMedia } from '../../../types'
import { Helpers, MOBILE_WIDTH_THRESHOLD } from '../../../services/helpers'
import { Thumbnail } from '../../Thumbnail'
import { StoreComponent } from '../../../services/store'

type Mapping = Array<{ x: number, y: number, size: number }>

const DESKTOP_NUMBER_OF_COLUMNS = 6
const DESKTOP_NUMBER_OF_ROW = 3
const MOBILE_NUMBER_OF_COLUMNS = 3
const MOBILE_NUMBER_OF_ROW = 6

// define items positions in the grid [x, y, size]
const DESKTOP_MOSAIC_MAPPING: Mapping = [
  { x: 0, y: 0, size: 2 },
  { x: 2, y: 0, size: 1 },
  { x: 2, y: 1, size: 1 },
  { x: 0, y: 2, size: 1 },
  { x: 1, y: 2, size: 1 },
  { x: 2, y: 2, size: 1 },
  { x: 3, y: 0, size: 1 },
  { x: 4, y: 0, size: 1 },
  { x: 5, y: 0, size: 1 },
  { x: 3, y: 1, size: 2 },
  { x: 5, y: 1, size: 1 },
  { x: 5, y: 2, size: 1 },
]

const MOBILE_MOSAIC_MAPPING: Mapping = [
  { x: 0, y: 0, size: 2 },
  { x: 2, y: 0, size: 1 },
  { x: 2, y: 1, size: 1 },
  { x: 0, y: 2, size: 1 },
  { x: 1, y: 2, size: 1 },
  { x: 2, y: 2, size: 1 },
  { x: 0, y: 3, size: 1 },
  { x: 0, y: 4, size: 1 },
  { x: 0, y: 5, size: 1 },
  { x: 1, y: 3, size: 2 },
  { x: 1, y: 5, size: 1 },
  { x: 2, y: 5, size: 1 },
]

class Mosaic extends StoreComponent {

  public render() {
    if (!this.context.root) {
      return null
    }
    return (
      <div
        className={`${this.context.config.classPrefix}-layout-mosaic`}
        style={mosaicStyle}
      >
        {!this.context.isMobile ? this.renderDesktop() : this.renderMobile()}
      </div>
    )
  }

  public renderDesktop() {
    const mediaChunks = Helpers.chunkArray(this.context.data.content.medias, DESKTOP_MOSAIC_MAPPING.length)
    const grids = mediaChunks.map((chunk, i) =>
      <React.Fragment key={i}>
        {this.generateGrid(chunk, DESKTOP_MOSAIC_MAPPING, DESKTOP_NUMBER_OF_COLUMNS, DESKTOP_NUMBER_OF_ROW)}
      </React.Fragment>,
    )

    return grids
  }

  public renderMobile() {
    const mediaChunks = Helpers.chunkArray(this.context.data.content.medias, MOBILE_MOSAIC_MAPPING.length)
    const grids = mediaChunks.map((chunk, i) =>
      <React.Fragment key={i}>
        {this.generateGrid(chunk, MOBILE_MOSAIC_MAPPING, MOBILE_NUMBER_OF_COLUMNS, MOBILE_NUMBER_OF_ROW)}
      </React.Fragment>,
    )

    return grids
  }

  /**
   * Generate a grid, composed of columns * rows cases
   * with the correct css rules to position Thumbnails
   * @param medias list of medias
   * @param mapping positions
   * @param columns number of columns
   * @param rows number of row
   */
  private generateGrid(
    medias: IMedia[],
    mapping: Mapping,
    columns: number,
    rows: number,
  ) {
    const gridStyle = {
      ...gridStyleTemplate,
      gridTemplateColumns: Helpers.createArray(columns).map(() => `${100 / columns}%`).join(' '),
      gridTemplateRows: Helpers.createArray(rows).map(() => 'auto').join(' '),
    }

    const thumbnails = medias.map((media, i) => {
      const { x, y, size } = mapping[i] // get the position of the current media
      const style: React.CSSProperties = {
        gridColumn: `${x + 1} / span ${size}`,
        gridRow: `${y + 1} / span ${size}`,
      }
      return (
        <div
          className={`${this.context.config.classPrefix}-item`}
          style={style}
          key={i}
        >
          <Thumbnail media={media} position={i} />
        </div>
      )
    })

    return (
      <React.Fragment>
        <div
          className={`${this.context.config.classPrefix}-grid`}
          style={gridStyle}
        >
          {thumbnails}
        </div>
        <br />
      </React.Fragment>
    )
  }

}

const mosaicStyle: React.CSSProperties = {
  textAlign: 'center',
}

const gridStyleTemplate: React.CSSProperties = {
  display: 'inline-grid',
  width: '100%',
}

export { Mosaic }
