import * as React from 'react'

export interface SocialNetworkIconProps {
  size?: number;
  children?: React.ReactNode;
}

class SocialNetworkIcon extends React.Component<SocialNetworkIconProps, {}> {
  public render() {
    return (
      <svg
        version='1.1'
        viewBox='0 0 260 260'
        xmlSpace='preserve'
        style={{
          width: this.props.size || 20,
          verticalAlign: 'baseline',
        }}
      >
        {this.props.children}
      </svg>
    )
  }
}

export { SocialNetworkIcon }
