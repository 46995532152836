import * as React from 'react';
import { useRef, forwardRef, useState, useEffect } from 'react';
import { StoreContext } from '../../../../services/store';
import { style, classes } from 'typestyle';
import { Tweet } from '../Tweet';
import { UserName } from '../UserName';
import { PlayIcon } from '../../../Icons/Play';
import { onKeyDownCallback, onKeyDownEventCallback } from '../../../../utils/onKeyDown';

const Content = forwardRef<HTMLDivElement>((props, contentRef) => {
  const context = React.useContext(StoreContext);
  const { image, video, type, post, username, source } = context.post!;

  const [pause, setPause] = useState<boolean>(true);
  const videoRef = useRef<HTMLVideoElement>(null);

  const containerStyle = context.isMobile ? mobileStyle : desktopStyle;
  const contentClass = context.isMobile ? mobileContentClass : desktopContentClass;

  const isTextMedia = context.post?.type === 'text';

  const playVideo = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (videoRef.current) {
      videoRef.current.play();
    }
    setPause(false);
  };

  const pauseVideo = () => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
    setPause(true);
  };

  // reload the video each time the context changes, and set it on pause on initial load.
  useEffect(() => {
    videoRef.current?.load();
    setPause(true);
  }, [video]);

  if (isTextMedia) {
    return <Tweet media={context.post!} className={classes(commonContentClass, contentClass)} />;
  }

  return (
    <div
      style={containerStyle}
      className={`${context.config.classPrefix}-postviewer-content`}
      aria-label={`${source} content created by ${username}.`}
      tabIndex={0}
      ref={contentRef}
    >
      {!video ? (
        <a
          href={post}
          target='_blank'
          className={classes(commonContentClass, contentClass)}
          style={{ backgroundImage: `url(${image})` }}
          role='link'
          aria-label='go to the original post'
        />
      ) : (
        <video
          ref={videoRef}
          className={classes(videoContentClass, contentClass)}
          controls={false}
          onClick={pauseVideo}
          onKeyDown={(e) => onKeyDownCallback(e, pauseVideo)}
          onEnded={pauseVideo}
          tabIndex={0}
          role='button'
          aria-label='pause video'
        >
          <source src={video} type='video/mp4'></source>
        </video>
      )}

      {type === 'video' && pause ? (
        <div
          className={classes(playIconStyle)}
          onClick={playVideo}
          onKeyDown={(e) => onKeyDownEventCallback(e, playVideo)}
          tabIndex={0}
          role='button'
          aria-label='play video'
        >
          <PlayIcon style={{ flex: 1 }} />
        </div>
      ) : null}

      {!(context.isMobile || context.data.settings.shop_this_look) && (
        <div className={userNameWrapper}>
          <UserName />
        </div>
      )}

      {!video ? (
        <div
          className={context.data.settings.shop_this_look ? imageFillerClass : undefined}
          style={
            context.data.settings.shop_this_look
              ? {
                  backgroundImage: `url(${image})`,
                }
              : {}
          }
        ></div>
      ) : null}
    </div>
  );
});

export const desktopStyle: React.CSSProperties = {
  position: 'relative',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'center',
  overflow: 'hidden',
};

export const mobileStyle: React.CSSProperties = {
  height: '85vw',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  position: 'relative',
  overflow: 'hidden',
};

const videoContentClass = style({
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  overflow: 'hidden',
});

const playIconStyle = style({
  position: 'absolute',
  cursor: 'pointer',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const userNameWrapper = style({
  position: 'relative',
  marginTop: 5,
  marginLeft: 10,
  marginBottom: 5,
});

const commonContentClass = style({
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  overflow: 'hidden',
  zIndex: 2,
});

const desktopContentClass = style({
  display: 'flex',
  flex: 1,
});

const mobileContentClass = style({
  width: '100%',
  height: '100%',
});

const imageFillerClass = style({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundSize: 'cover',
  zIndex: 1,
  backgroundRepeat: 'repeat',
  backgroundPosition: 'center',
  filter: 'blur(9px)',
  transform: 'scale(1.1)',
});

export { Content };
