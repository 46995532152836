import * as React from 'react';
import { StoreComponent } from '../services/store';

type NodeRef = Element | null;

// Time in millisecond before sending an event
const VISILITY_TIME_FOR_EVENT = 500;

interface Props {
  /**
   * children must be a function waiting for a ref callback
   * to give to the children element
   */
  children(ref: (node: NodeRef) => void): React.ReactNode;
}

/**
 * Observe visibility of a children component
 */
export class Visibility extends StoreComponent<Props> {
  private observer?: IntersectionObserver;
  private eventTimeout: ReturnType<typeof setTimeout> | undefined;
  private triggered: boolean = false;
  private childrenRef?: NodeRef;

  public render() {
    return this.props.children(this.onChildrenRef);
  }

  public componentWillUnmount() {
    if (this.observer && this.childrenRef) {
      this.observer.unobserve(this.childrenRef);
    }
  }

  /**
   * Triggered each times the ref changes
   * @param ref Ref to the children
   */
  private onChildrenRef = (ref: NodeRef) => {
    if (!this.observer) {
      this.observer = new IntersectionObserver(this.handleIntersection, {
        root: null,
        threshold: [0.5],
      });
    }
    if (this.childrenRef) {
      // unobserve old ref
      this.observer.unobserve(this.childrenRef);
    }
    if (ref) {
      this.observer.observe(ref);
    }
    this.childrenRef = ref;
  };

  private handleIntersection = (entries: IntersectionObserverEntry[]) => {
    const [entry] = entries;
    this.visibilityChange(entry.isIntersecting);
  };

  private visibilityChange = (isVisible: boolean) => {
    if (this.eventTimeout) {
      clearTimeout(this.eventTimeout);
    }
    if (isVisible && !this.triggered) {
      this.eventTimeout = setTimeout(() => {
        this.triggered = true;
        this.context.triggerEvent('widgetViewed', { layout: this.context.data.settings.type });
      }, VISILITY_TIME_FOR_EVENT);
    }
  };
}
