import { EventCallback, EventCallbacks, EventMap, EventMapKeys, IWidgetEvents } from '../types';
import { Debug } from '../debug';

export class WidgetEvents implements IWidgetEvents {
  protected destroyed: boolean = false;
  private eventCallbacks: { [key in EventMapKeys]?: EventCallbacks<EventMap[key]> } = {};

  public onEvent<K extends EventMapKeys>(eventName: K, callback: EventCallback<EventMap[K]>) {
    if (this.destroyed) {
      return;
    }
    Debug.try(() => {
      let callbacks = this.getCallbacks(eventName);
      if (callbacks.indexOf(callback) === -1) {
        callbacks.push(callback);
      }
      this.eventCallbacks[eventName] = callbacks as any; // fixme
    });
  }

  public offEvent<K extends EventMapKeys>(eventName: K, callback?: EventCallback<EventMap[K]>) {
    Debug.try(() => {
      let callbacks = this.getCallbacks(eventName);
      if (callback) {
        const i = callbacks.indexOf(callback);
        if (i > -1) {
          callbacks.splice(i, 1);
        }
      } else {
        callbacks = [];
      }
      this.eventCallbacks[eventName] = callbacks as any; // fixme
    });
  }

  public hasSubscribed(eventName: EventMapKeys): boolean {
    return !!this.eventCallbacks[eventName]?.length;
  }

  /**
   * Called when the widget is destroyed
   * Remove all the events
   */
  protected destroy() {
    this.eventCallbacks = {};
  }

  protected triggerEvent<K extends EventMapKeys>(eventName: K, event: EventMap[K]) {
    if (this.destroyed) {
      return;
    }
    const callbacks = this.getCallbacks(eventName);
    callbacks.forEach((callback) => callback(event));
  }

  private getCallbacks<K extends EventMapKeys>(eventName: K): EventCallbacks<EventMap[K]> {
    let callbacks = this.eventCallbacks[eventName] as EventCallbacks<EventMap[K]> | undefined;
    if (!callbacks) {
      callbacks = this.eventCallbacks[eventName] = [];
    }
    return callbacks;
  }
}
