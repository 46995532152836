
const tuple = <T extends string[]>(...args: T) => args
const LEVELS = tuple('error', 'debug')

export type Level = typeof LEVELS[number]

export abstract class Debug {
  public static level: number = 0

  public static log(label: string, ...args: any[]) {
    if (Debug.level >= LEVELS.indexOf('debug')) {
      console.debug(label, ...args)
    }
  }

  public static error(err: Error | string, ...args: any[]) {
    console.error('[AdalongWidget]', err, ...args)
  }

  public static setLevel(level: Level) {
    Debug.level = LEVELS.indexOf(level)
  }

  public static try(fn: () => any) {
    try {
      const r = fn()
      if (r instanceof Promise) {
        r.catch((err) => this.error(err))
      }
      return r
    } catch (error) {
      this.error(String(error))
    }
  }
}