import * as React from 'react';
import { useState, useEffect } from 'react';

/**
 * Observe intersection for the given elements
 * Return the list of elements intersected at least once
 */
export const useIntersectionObserver = <El extends Element>(
  elementRefs: (El | null)[],
  {
    threshold = 0,
    root = null,
    rootMargin = '0%',
  }: IntersectionObserverInit,
) => {
  const [intersectingElements, setIntersectingElements] = useState<Set<Element>>(new Set());

  useEffect(() => {
    // Keep list of intersected elements
    const localIntersectingElements = new Set<Element>();

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          localIntersectingElements.add(entry.target);
        }
      });
      // set the list to update components using this hook
      setIntersectingElements(new Set(localIntersectingElements));
    }, {
      threshold,
      root,
      rootMargin,
    });

    elementRefs.forEach((elementRef) => {
      if (elementRef) {
        observer.observe(elementRef);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, [elementRefs]);

  return intersectingElements;
};
