import * as React from 'react';

interface Props extends React.HTMLAttributes<HTMLOrSVGElement> {
  color?: string;
  size?: number;
}

class PlayIcon extends React.Component<Props> {
  public render() {
    const { color = 'rgba(255, 255, 255, 0.7)', size = 13, ...props } = this.props;
    return (
      <svg
        width='30%'
        viewBox='0 0 24 24'
        fill={color}
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
        <path d='M8.286 3.407A1.5 1.5 0 0 0 6 4.684v14.632a1.5 1.5 0 0 0 2.286 1.277l11.888-7.316a1.5 1.5 0 0 0 0-2.555L8.286 3.407z' />
      </svg>
    );
  }
}

export { PlayIcon };
