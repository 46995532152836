import * as React from 'react'

interface Props extends React.HTMLAttributes<HTMLOrSVGElement> {
  size?: number
}

class BackIcon extends React.Component<Props> {
  public render() {
    const { size = 24, ...props } = this.props
    return (
      <svg
        width={size}
        height={size}
        viewBox='0 0 256 256'
        enableBackground='new 0 0 256 256'
        xmlSpace='preserve'
        {...props}
      >
        <path d='M179.199,38.399c0,1.637-0.625,3.274-1.875,4.524l-85.076,85.075l85.076,85.075c2.5,2.5,2.5,6.55,0,9.05s-6.55,2.5-9.05,0  l-89.601-89.6c-2.5-2.5-2.5-6.551,0-9.051l89.601-89.6c2.5-2.5,6.55-2.5,9.05,0C178.574,35.124,179.199,36.762,179.199,38.399z' />
      </svg>
    )
  }
}

export { BackIcon }
