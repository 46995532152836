import * as React from 'react'
import { SocialNetworkIcon, SocialNetworkIconProps } from '../SocialNetworkIcon'

class TwitterIcon extends React.Component<SocialNetworkIconProps, {}> {
  public render() {
    return (
      <SocialNetworkIcon {...this.props}>
        <path style={{ transform: 'translate(-42px, -100px) scale(0.7)' }}
          d='M196.9,462C317.2,462,383,362.3,383,275.9c0-2.8,0-5.6-0.2-8.5c12.8-9.3,23.8-20.7,32.6-33.9  c-11.9,5.3-24.6,8.8-37.6,10.3c13.7-8.2,23.9-21,28.8-36.2c-12.8,7.6-26.9,13-41.5,15.9c-24.8-26.3-66.2-27.6-92.5-2.8  c-17,16-24.2,39.8-18.9,62.5c-52.6-2.6-101.6-27.5-134.8-68.3c-17.4,29.9-8.5,68.1,20.2,87.3c-10.4-0.3-20.6-3.1-29.7-8.2  c0,0.3,0,0.5,0,0.8c0,31.1,22,57.9,52.5,64.1c-9.6,2.6-19.7,3-29.5,1.1c8.6,26.6,33.1,44.9,61.1,45.4  c-23.2,18.2-51.8,28.1-81.2,28.1c-5.2,0-10.4-0.3-15.6-0.9C126.5,451.8,161.3,462,196.9,462'
        />
      </SocialNetworkIcon>
    )
  }
}

export { TwitterIcon }
