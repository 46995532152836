import * as React from 'react';
import { useEffect } from 'react';
import { LeftArrowIcon } from '../../Icons/LeftArrow';
import { RightArrowIcon } from '../../Icons/RightArrow';
import { StoreContext } from '../../../services/store';
import { classes, style } from 'typestyle';

interface Props<T> {
  items: T[];
  nbElems: number;
  style?: React.CSSProperties;
}

interface State {
  slidePosition: number;
  squareSize: number;
  canSlideLeft: boolean;
  canSlideRight: boolean;
}

const Carousel = (props: Props<JSX.Element>) => {
  const context = React.useContext(StoreContext);
  const slideRef = React.useRef<HTMLDivElement>(null);
  let lastScrollValue = 0;
  let lastSlidePosition = 0;
  const [carouselState, setCarouselState] = React.useState<State>({
    slidePosition: 0, // index of the first visible content
    squareSize: 0,
    canSlideLeft: false,
    canSlideRight: props.items.length > props.nbElems,
  });

  useEffect(() => {
    if (slideRef.current) {
      calculateSquareSize();
    }
  }, [slideRef, slideRef.current]);

  useEffect(() => {
    context.setStoreState({
      canSlideLeft: false,
      canSlideRight: props.items.length > props.nbElems,
    });
  }, []);

  let _carouselStyle = {
    ...carouselStyle,
    height: carouselState.squareSize,
    ...props.style,
  };

  useEffect(() => {
    window.addEventListener('resize', () => calculateSquareSize(true));
    return () => {
      window.removeEventListener('resize', () => calculateSquareSize(true));
    };
  }, []);

  useEffect(() => {
    // @ts-ignore
    const evtListener = ({ detail }) => {
      onArrowClick(undefined, detail, carouselState);
    };
    // @ts-ignore
    window.addEventListener('adalongWidget_slide', evtListener);
    return () => {
      // @ts-ignore
      window.removeEventListener('adalongWidget_slide', evtListener);
    };
  }, [carouselState]);

  useEffect(() => {
    const slidePosition = Math.max(Math.min(props.items.length - numElems() + 1, carouselState.slidePosition), 0);
    setCarouselState({
      squareSize: carouselState.squareSize,
      slidePosition,
      canSlideLeft: slidePosition > 0,
      canSlideRight: slidePosition < props.items.length - props.nbElems,
    });
    context.setStoreState({
      canSlideLeft: slidePosition > 0,
      canSlideRight: slidePosition < props.items.length - props.nbElems,
    });
  }, [props.items.length]);

  const renderNativeScroll = () => {
    return (
      <div style={nativeScrollSlideStyle} ref={slideRef} onScroll={onMobileScroll}>
        <div style={nativeScrollSlideContainerStyle}>{renderMedia()}</div>
      </div>
    );
  };

  function displayArrowNavorNativeScroll() {
    if (context.isMobile) {
      return context.config.displayMobileCarouselArrows ? renderArrowNavigation() : renderNativeScroll();
    }
    return renderArrowNavigation();
  }

  const renderArrowNavigation = () => {
    const _arrowStyle = {
      ...arrowStyle,
    };

    const showArrows = props.nbElems < props.items.length && !context.config.hideArrows;
    return (
      <>
        <div
          style={showArrows ? buttonStyle : hiddenButton}
          className={classes(arrowClass, `${context.config.classPrefix}-arrow`, `${context.config.classPrefix}-arrow-left`)}
        >
          <LeftArrowIcon
            style={carouselState.canSlideLeft ? _arrowStyle : arrowDisabled}
            onClick={(e) => onArrowClick(e, 'left')}
            onKeyDown={(e) => onArrowClick(e, 'left')}
            tabIndex={0}
            role='button'
            aria-label='previous'
          />
        </div>
        <div style={desktopSlideStyle} ref={slideRef}>
          <div
            style={{
              ...desktopSlideContainerStyle,
              left: -(carouselState.slidePosition * carouselState.squareSize),
            }}
          >
            {renderMedia()}
          </div>
        </div>
        <div
          style={showArrows ? buttonStyle : hiddenButton}
          className={classes(arrowClass, `${context.config.classPrefix}-arrow`, `${context.config.classPrefix}-arrow-right`)}
        >
          <RightArrowIcon
            style={carouselState.canSlideRight ? _arrowStyle : arrowDisabled}
            onClick={(e) => onArrowClick(e, 'right')}
            onKeyDown={(e) => onArrowClick(e, 'right')}
            tabIndex={0}
            role='button'
            aria-label='next'
          />
        </div>
      </>
    );
  };

  const renderMedia = (): JSX.Element[] => {
    return props.items.map((media, i) => (
      <div
        style={{
          ...thumbnailStyle,
          width: carouselState.squareSize,
        }}
        key={i}
      >
        {media}
      </div>
    ));
  };

  function numElems(): number {
    if (!slideRef.current) {
      return props.nbElems;
    }

    const minWidth = 125;
    // Display minimum one media
    return slideRef.current.clientWidth / props.nbElems > minWidth ? props.nbElems : Math.floor(slideRef.current.clientWidth / minWidth) || 1;
  }

  function calculateSquareSize(resize?: boolean): number {
    // calculate squareSize depending on slide container width and number of columns
    if (slideRef.current) {
      const ratio = slideRef.current.clientWidth / numElems();
      // On mobile we need on offset to understand the carousel is scrollable
      const squareSize = !context.isMobile ? ratio : 0.9 * ratio;
      const firstCalculation = carouselState.squareSize === 0;
      if (firstCalculation || resize) {
        setCarouselState((state) => ({
          ...state,
          squareSize,
        }));
      }
      return squareSize;
    }
    return 0;
  }

  const onMobileScroll = (event: any) => {
    let dir: 'left' | 'right';
    let scrollValue = event.currentTarget.scrollLeft;
    let slidePosition = Math.floor(scrollValue / carouselState.squareSize);

    if (!slideRef.current) {
      return;
    }

    if (scrollValue > lastScrollValue) {
      dir = 'left';
    } else {
      dir = 'right';
    }

    lastScrollValue = scrollValue;

    if (lastSlidePosition !== slidePosition) {
      lastSlidePosition = slidePosition;

      context.triggerEvent('carouselNativeScroll', {
        direction: dir,
        position: lastSlidePosition,
        originalEvent: event.nativeEvent,
      });
    }
  };

  function onArrowClick(
    event: React.MouseEvent<HTMLOrSVGElement, MouseEvent> | React.KeyboardEvent<HTMLOrSVGElement> | undefined,
    dir: 'right' | 'left',
    state?: State,
    shift?: number
  ) {
    if (!slideRef.current) {
      return;
    }

    const max = props.items.length;
    const width = slideRef.current.clientWidth;

    let { slidePosition, squareSize, canSlideRight, canSlideLeft } = state ?? carouselState;

    const move = Math.floor(width / squareSize);

    if (dir === 'right' && canSlideRight) {
      slidePosition = shift ? shift + move : slidePosition + move;
    } else if (dir === 'left' && canSlideLeft) {
      slidePosition = shift ? shift - move : slidePosition - move;
    }
    slidePosition = Math.max(0, Math.min(max - move, slidePosition));
    setCarouselState({
      squareSize: carouselState.squareSize,
      slidePosition,
      canSlideLeft: slidePosition > 0,
      canSlideRight: slidePosition < max - move,
    });
    context.setStoreState({
      canSlideLeft: slidePosition > 0,
      canSlideRight: slidePosition < max - move,
    });
    if (event) {
      context.triggerEvent('carouselArrowClicked', {
        position: slidePosition,
        originalEvent: event.nativeEvent,
        direction: dir,
      });
    }
  }

  return (
    <div className={`${context.config.classPrefix}-layout-carousel`} style={_carouselStyle}>
      {displayArrowNavorNativeScroll()}
    </div>
  );
};

const carouselStyle: React.CSSProperties = {
  width: '100%',
  display: 'flex',
};

const desktopSlideStyle: React.CSSProperties = {
  flex: 1,
  height: '100%',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  position: 'relative',
};

const nativeScrollSlideStyle: React.CSSProperties = {
  height: '100%',
  minWidth: '100%',
  overflowX: 'scroll',
  overflowY: 'hidden',
  whiteSpace: 'nowrap',
  position: 'relative',
};

const desktopSlideContainerStyle: React.CSSProperties = {
  position: 'absolute',
  height: '100%',
  top: 0,
  left: 0,
  transition: 'left 0.5s',
};

const nativeScrollSlideContainerStyle: React.CSSProperties = {
  position: 'absolute',
  height: '100%',
};
const buttonStyle: React.CSSProperties = {
  flexShrink: 0,
  height: '100%',
  padding: 8,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
};

const hiddenButton: React.CSSProperties = {
  ...buttonStyle,
  visibility: 'hidden',
};

const arrowStyle: React.CSSProperties = {};

const arrowDisabled: React.CSSProperties = {
  filter: 'brightness(50%)',
};

const arrowClass = style({
  color: 'black',
});

const thumbnailStyle: React.CSSProperties = {
  display: 'inline-flex',
  height: '100%',
};

export { Carousel };
