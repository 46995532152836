import * as React from 'react'
import { IMedia } from '../../../../types'
import { classes, style } from 'typestyle'

interface Props {
  media: IMedia,
  className?: (React.HTMLAttributes<HTMLDivElement>['className']),
}

interface State {
  width: number
}

export class Tweet extends React.Component<Props, State> {
  private divElement: HTMLDivElement | null = null

  constructor(props: Props) {
    super(props)
    this.state = {
      width: 200,
    }
  }

  public render() {
    const { media, className } = this.props
    return <div
      className={classes(className, classText)}
      style={this.getStyle()}
      ref={(divElement) => { this.divElement = divElement }}
    >
      <div className={classScroll}>
        {this.textToNodes(media.caption)}
      </div>
    </div>
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
  }

  public componentDidMount() {
    window.addEventListener('resize', this.updateDimensions)
    this.updateDimensions()
  }

  private updateDimensions = () => {
    setTimeout(() => {
      if (this.divElement) {
        const width = this.divElement.clientWidth
        this.setState({ width })
      }
    }, 200)
  }

  private renderLineBreaks(sentence: string) {
    const output: Array<string | JSX.Element> = sentence.split('\n')
    for (let i = output.length - 2; i > 0; i--) {
      output.splice(i, 0, <br />)
    }
    return output
  }

  private textToNodes(text: string) {
    const regex = RegExp(/([#@][^ .,!?]+)/)
    const splitted = text.split(regex)

    return splitted.reduce((prev, sp) => {
      if (regex.test(sp)) {
        prev.push(this.highlight(sp))
        return prev
      } else {
        return prev.concat(...this.renderLineBreaks(sp))
      }
    }, [] as Array<JSX.Element | string>)
  }

  private highlight(keyword: string) {
    return <span className={classKeyword}>
      {keyword}
    </span>
  }

  private getStyle(): React.CSSProperties {
    return {
      fontSize: this.state.width / 17,
      flexDirection: 'column',
      display: 'flex',
    }
  }
}

const classScroll = style({
  overflowY: 'scroll',
  scrollbarWidth: 'none',
  $nest: {
    '&::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.3)',
    },

    '&::-webkit-scrollbar': {
      width: 4,
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#454545',
    },
  },
})

const classText = style({
  overflow: 'hidden',
  position: 'relative',
  backgroundColor: '#212121',
  color: '#FFFFFF',
  width: '86% !important',
  height: 'revert',
  padding: '7%',
  paddingRight: 'calc(7% - 5px)',
  justifyContent: 'center',
  fontFamily: '"Public Sans", sans-serif',
  margin: 4,
  wordBreak: 'break-word',
  whiteSpace: 'initial',
  textAlign: 'center',
})

const classKeyword = style({
  color: '#1DA1F2',
  overflowWrap: 'break-word',
})
