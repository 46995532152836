import * as React from 'react';
import { Config, IData, IMedia } from '../types';
import { WidgetEvents } from './events';
import { Debug } from '../debug';

export const DEFAULT_CONFIG: Config = {
  classPrefix: 'adl-wdgt',
  shopThisLookText: 'SHOP THE LOOK',
  moreLikeThisText: 'MORE LIKE THIS',
};

export interface StoreState {
  exportState: (getState: () => StoreState, setter: StateSetter) => void;
  post: IMedia | null;
  data: IData;
  forceMobile: boolean;
  isMobile: boolean;
  sliderPosition: number;
  root: Element | null;
  config: Config;
  triggerEvent: WidgetEvents['triggerEvent'];
  setStoreState: React.Component<StoreProps, StoreState>['setState'];
  canSlideLeft: boolean;
  canSlideRight: boolean;
}

export interface StoreProps {
  state: Partial<StoreState>;
  children: React.ReactNode;
}

export const initial: StoreState = {
  exportState: () => {},
  post: null,
  data: {} as any,
  forceMobile: false,
  isMobile: false,
  root: null,
  sliderPosition: 0,
  config: DEFAULT_CONFIG,
  triggerEvent: () => {},
  setStoreState: () => {},
  canSlideLeft: false,
  canSlideRight: true,
};

export const StoreContext = React.createContext(initial);
export type StateSetter = React.Component<StoreProps, StoreState>['setState'];
/**
 * Class to provide the context
 * State set via the props will always overwrite the component state
 */
export class StoreProvider extends React.Component<StoreProps, StoreState> {
  constructor(props: any) {
    super(props);
    this.state = {
      ...initial,
      ...props.state,
      setStoreState: this.setState.bind(this),
    };
    props.state.exportState(() => this.state, this.state.setStoreState);
  }

  public render() {
    return <StoreContext.Provider value={{ ...this.state }}>{this.props.children}</StoreContext.Provider>;
  }

  public componentDidCatch(err: Error, info: any) {
    Debug.error(err, info);
  }

  public componentDidUpdate(prevProps: StoreProps) {
    if (prevProps !== this.props) {
      this.setState({
        ...this.state,
        ...this.props.state,
      });
    }
  }
}

/**
 * Every component connected to the store needs to extend this class
 */
export abstract class StoreComponent<P = {}, S = {}, SS = any> extends React.Component<P, S, SS> {
  public static contextType = StoreContext;
  public context!: StoreState;
  static context: any;
}
