export const onKeyDownEventCallback = <KeyboardType,>(
  e: React.KeyboardEvent<KeyboardType>,
  callback: (e: React.KeyboardEvent<KeyboardType>) => void
) => {
  {
    if (e.key === 'Enter' || e.key === ' ') {
      callback(e);
    } else return;
  }
};

export const onKeyDownCallback = <KeyboardType,>(
  e: React.KeyboardEvent<KeyboardType>,
  callback: () => void
) => {
  {
    if (e.key === 'Enter' || e.key === ' ') {
      callback();
    } else return;
  }
};
