import { IMedia } from '../types';

export const MOBILE_WIDTH_THRESHOLD = 740;

abstract class Helpers {
  public static createArray(length: number) {
    return Array.apply(null, Array(length));
  }
  public static chunkArray<T>(array: T[], chunkSize: number) {
    let chunks: T[][] = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  }

  public static getAbsoluteBackgroundColor(element?: Element | null): string {
    if (!element) {
      return 'white';
    }
    const bg = window.getComputedStyle(element).getPropertyValue('background-color');
    if (bg === 'transparent' || bg === 'rgba(0, 0, 0, 0)') {
      if (element.parentElement) {
        return this.getAbsoluteBackgroundColor(element.parentElement);
      }
      return 'white';
    }
    return bg;
  }

  public static isValidJson(jsonString: string): boolean {
    try {
        var o = JSON.parse(jsonString);
        if (o && typeof o === "object") {
            return true;
        }
    }
    catch (e) { }
    return false;
};

  /**
   * Check if the client device is a mobile
   */
  public static isMobile(width: number): boolean {
    return width < MOBILE_WIDTH_THRESHOLD;
  }

  public static pushPostToHistory(post: IMedia) {
    history.pushState({ widgetPost: post }, `widgetpost-${post.postIndex}`, null);
  }

  public static replacePostInHistory(post: IMedia) {
    history.replaceState({ widgetPost: post }, `widgetpost-${post.postIndex}`, null);
  }

  public static uniqId(): string {
    return `${Date.now()}${Math.floor(Math.random() * 1000)}`;
  }

  /**
   * Randomly create an id that have low chances to collide
   */
  public static randomId() {
    // convert to base 36 to have numbers + letters
    return Math.random().toString(36).substr(2, 8);
  }
}

export { Helpers };
