import * as React from 'react';
import { IMedia } from '../../types';
import { StoreComponent } from '../../services/store';
import { PlayIcon } from '../Icons/Play';
import { classes, style } from 'typestyle';
import { Helpers } from '../../services/helpers';
import { ShopSvgIcon } from '../Icons/Shop';
import { Tweet } from '../PostViewer/components/Tweet';
import { onKeyDownEventCallback } from '../../utils/onKeyDown';

type NodeRef = Element | null;

interface Props {
  media: IMedia;
  position: number;
}
interface State {
  showProductBag: boolean;
  imageVisible: boolean;
}
class Thumbnail extends StoreComponent<Props, State> {
  private imageRef?: NodeRef;
  private imageObserver?: IntersectionObserver;

  constructor(props: Props) {
    super(props);
    this.state = {
      showProductBag: false,
      imageVisible: false,
    };
  }
  public render() {
    const { media, position } = this.props;
    const isTextMedia = media.type === 'text';
    const shopIconSize = this.context.isMobile ? 14 : 30;
    return (
      <div
        ref={this.handleThumbnailRef.bind(this)}
        data-url={media.post}
        data-position={position}
        className={`${this.context.config.classPrefix}-thumbnail`}
        style={thumbnailStyle}
        onClick={(e) => this.onOpen(e)}
        onKeyDown={(e) => onKeyDownEventCallback(e, this.onOpen.bind(this))}
        tabIndex={0}
        role='link'
        aria-label={`open the preview of the ${media.source} content created by ${media.username}`}
        onMouseEnter={(e) => this.onHover(e)}
        onMouseLeave={() =>
          this.setState({
            showProductBag: false,
          })
        }
        onMouseOver={() =>
          this.setState({
            showProductBag: true,
          })
        }
      >
        <div className={containerClass}>
          {isTextMedia ? (
            <Tweet media={media} />
          ) : (
            <div
              style={{
                ...imgStyle,
                backgroundImage: this.state.imageVisible
                  ? `url("${
                      media.type === 'video' && media.thumbnail ? media.thumbnail : media.image
                    }")`
                  : undefined,
              }}
            >
              {media.products?.length &&
              this.context.data.settings.shop_this_look &&
              this.state.showProductBag ? (
                <ShopSvgIcon
                  className={classes(productIconClass, {
                    [productIconMobileClass]: this.context.isMobile,
                  })}
                  size={shopIconSize}
                />
              ) : null}
              {media.type === 'video' ? <PlayIcon size={30} style={playIconStyle} /> : null}
              {media.username ? (
                <div data-username={media.username} className={overlayClass}>
                  {media.username}
                </div>
              ) : null}
            </div>
          )}
        </div>
        {/* wait for the image to be in the viewport before loading it */}
        {this.state.imageVisible && (
          <img
            src={media.image}
            alt={`${media.source} content created by ${media.username}.`}
            onError={this.imageError.bind(this)}
            style={{ display: 'none' }}
            onLoad={this.imageLoaded.bind(this)}
          />
        )}
      </div>
    );
  }

  private onOpen(
    e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.KeyboardEvent<HTMLDivElement>
  ) {
    const { media, position } = this.props;
    this.context.triggerEvent('thumbnailClicked', {
      post: media,
      position,
      originalEvent: e.nativeEvent,
    });
    this.context.triggerEvent('postOpened', { post: media });
    this.context.setStoreState({ post: media });
    Helpers.pushPostToHistory(media);
  }

  private imageError(error: React.SyntheticEvent<HTMLImageElement, Event>) {
    this.context.triggerEvent('thumbnailUnavailable', {
      post: this.props.media,
      originalEvent: error.nativeEvent,
    });
    this.deleteMedia(this.props.media.postIndex);
  }

  private imageLoaded(e: React.SyntheticEvent<HTMLImageElement, Event>) {
    this.context.triggerEvent('thumbnailLoaded', {
      post: this.props.media,
      originalEvent: e.nativeEvent,
    });
  }

  private onHover(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    this.context.triggerEvent('thumbnailHover', {
      post: this.props.media,
      originalEvent: e.nativeEvent,
    });
  }

  private deleteMedia(index: number) {
    this.context.setStoreState((state) => {
      let data = { ...state.data };
      let medias = [...data.content.medias];
      let mediaIndex = medias.findIndex((media) => index === media.postIndex);
      if (mediaIndex > -1) {
        medias.splice(mediaIndex, 1);
      }
      data.content.medias = medias.map((media, idx) => {
        media.postIndex = idx;
        return media;
      });
      return { data };
    });
  }

  /**
   * Observe the thumbnail ref intersections when the ref changes
   */
  private handleThumbnailRef(ref: NodeRef) {
    if (!this.imageObserver) {
      this.imageObserver = new IntersectionObserver(this.handleImageIntersection, {
        root: null,
        threshold: [0.1],
      });
    }
    if (this.imageRef) {
      // unobserve old ref
      this.imageObserver.unobserve(this.imageRef);
    }
    if (ref) {
      this.imageObserver.observe(ref);
    }
    this.imageRef = ref;
  }

  private handleImageIntersection = (entries: IntersectionObserverEntry[]) => {
    const [entry] = entries;
    if (entry.isIntersecting && !this.state.imageVisible) {
      this.setState({ imageVisible: true });
    }
  };
}

const thumbnailStyle: React.CSSProperties = {
  paddingTop: '100%',
  width: '100%',
  position: 'relative',
  cursor: 'pointer',
};

const containerClass = style({
  position: 'absolute',
  height: '100%',
  width: '100%',
  top: 0,
  left: 0,
  display: 'flex',
  $nest: {
    '&:not(:hover) div[data-username]': {
      opacity: 0,
    },
  },
});

const imgStyle: React.CSSProperties = {
  position: 'relative',
  flex: 1,
  margin: 4,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundColor: '#282828',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  overflow: 'hidden',
};

const overlayClass = style({
  position: 'absolute',
  zIndex: 1,
  bottom: 0,
  left: 0,
  opacity: 1,
  width: '100%',
  backgroundColor: '#15151582',
  padding: '2px 6px',
  textAlign: 'left',
  fontSize: 14,
  overflow: 'hidden',
  transition: 'opacity 0.2s',
  color: 'white',
  fontFamily: 'sans-serif',
});

const playIconStyle = {
  flex: 1,
};

const productIconClass = style({
  $debugName: 'productIcon',
  position: 'absolute',
  top: 12,
  right: 12,
  color: 'white',
});

const productIconMobileClass = style({
  top: 2,
  right: 2,
});

export { Thumbnail };
