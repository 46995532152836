import * as React from 'react'

interface Props extends React.HTMLAttributes<HTMLOrSVGElement> {
  size?: number
}

class CloseIcon extends React.Component<Props> {
  public render() {
    const { size = 10, ...props } = this.props
    return (
      <svg width={size} height={size} viewBox='0 0 10 10' {...props}>
        <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'>
          <g transform='translate(-1119.000000, -204.000000)' stroke='#979797'>
            <g transform='translate(1120.000000, 205.000000)'>
              <line x1='0' y1='0' x2='8.56371581' y2='8.40611513'></line>
              <line x1='8.46065938' y1='0' x2='0' y2='8.50983213'></line>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

export { CloseIcon }
