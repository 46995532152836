import * as React from 'react'
import { Helpers } from '../../services/helpers'

export type Props = {
    color?: string,
    size?: number,
} & React.HTMLAttributes<HTMLOrSVGElement>

export function ShopSvgIcon({
    color = 'currentColor',
    size = 16,
    style,
    ...props
}: Props) {
    const [filterID] = React.useState('f' + Helpers.uniqId())

    return (
        <svg
            width={size}
            height={size}
            viewBox='-1 0 32 32'
            version='1.1'
            style={style}
            {...props}
        >
            <defs>
                <filter id={filterID}>
                    <feDropShadow dx='0' dy='1' stdDeviation='2' floodColor='black' floodOpacity='0.5'/>
                </filter>
            </defs>
            <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                <g filter={`url(#${filterID})`}>
                    <rect id='Rectangle' x='0' y='0' width='30' height='30'></rect>
                    <path
                        d='M22.5,7.5 L20,7.5 C20,4.7375 17.7625,2.5 15,2.5 C12.2375,2.5 10,4.7375 10,7.5 L7.5,7.5 C6.125,7.5 5,8.625 5,10 L5,25 C5,26.375 6.125,27.5 7.5,27.5 L22.5,27.5 C23.875,27.5 25,26.375 25,25 L25,10 C25,8.625 23.875,7.5 22.5,7.5 Z M12.5,12.5 C12.5,13.1875 11.9375,13.75 11.25,13.75 C10.5625,13.75 10,13.1875 10,12.5 L10,10 L12.5,10 L12.5,12.5 Z M15,5 C16.375,5 17.5,6.125 17.5,7.5 L12.5,7.5 C12.5,6.125 13.625,5 15,5 Z M20,12.5 C20,13.1875 19.4375,13.75 18.75,13.75 C18.0625,13.75 17.5,13.1875 17.5,12.5 L17.5,10 L20,10 L20,12.5 Z'
                        fill={color} fillRule='nonzero'
                    ></path>
                </g>
            </g>
        </svg>
    )
}