import * as React from 'react';
import { StoreContext } from '../../../../services/store';
import { style } from 'typestyle';
import { InstagramIcon } from '../../../Icons/Instagram';
import { TwitterIcon } from '../../../Icons/Twitter';
import { onKeyDownEventCallback } from '../../../../utils/onKeyDown';

export const UserName = () => {
  const context = React.useContext(StoreContext);
  const { username, source, post: postUrl } = context.post!;

  const icon = {
    instagram: <InstagramIcon />,
    twitter: <TwitterIcon />,
  }[source];

  const onUsernameClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent> | React.KeyboardEvent<HTMLAnchorElement>
  ) => {
    context.triggerEvent('originalPostOpened', {
      originalEvent: e.nativeEvent,
      post: context.post!,
    });
  };

  const userUrl = (): string | undefined => {
    if (!username) {
      return postUrl;
    }

    if (source === 'instagram') {
      return postUrl.slice(0, postUrl.indexOf('p/')) + username;
    }

    if (source === 'twitter') {
      return postUrl.slice(0, postUrl.indexOf('status/'));
    }
  };

  return (
    <div className={`${context.config.classPrefix}-postviewer-details-username`}>
      <span style={iconStyle}>{icon}</span>
      {username && (
        <a
          className={linkClass}
          href={userUrl()}
          target='_blank'
          onClick={onUsernameClick}
          onKeyDown={(e) => onKeyDownEventCallback(e, onUsernameClick)}
          tabIndex={0}
          role='link'
          aria-label={`go to ${username}'s profile on ${source}`}
        >
          <b className={usernameClass}>{username}</b>
        </a>
      )}
    </div>
  );
};

const usernameClass = style({
  fontFamily: "'Be Vietnam Pro', sans-serif",
  fontWeight: 600,
  letterSpacing: '0.3px',
  marginRight: 4,
  all: 'revert',
  fontSize: 10,
});
const iconStyle: React.CSSProperties = {
  verticalAlign: 'middle',
  marginRight: 5,
  marginBottom: 15,
};
const linkClass = style({
  textDecoration: 'inherit',
  color: 'inherit',
  $nest: {
    '&:hover': {
      color: 'gray',
    },
  },
});
