import * as React from 'react';
import { ArrowProps, LeftArrowIcon } from '../LeftArrow';

class RightArrowIcon extends React.Component<ArrowProps> {
  public render() {
    const { style, ...props } = this.props;

    return <LeftArrowIcon style={{ transform: 'rotate(180deg)', ...style }} {...props} />;
  }
}

export { RightArrowIcon };
